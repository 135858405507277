<template>
  <WidgetsModalsCommon :showModal="showModal" modalClass="newsletter-modal modal-lg" @close="closeModal({ state: false })">
    <div class="modal-header p-0">
      <WidgetsButton :classes="'btn btn-close'" :id="'profile_modal_close_btn'" @onClick="closeModal({ state: false })">
        <Icon name="ri-close-line"></Icon>
      </WidgetsButton>
    </div>
    <div class="modal-box">
      <div class="modal-image">
        <img :src="appStore.loadedAds[0].image" class="img-fluid" alt="newsletter">
      </div>
      <div class="modal-content content-padding">
        <div>
          <img src="/images/point_banner1.png" class="modal-logo" alt="logo" style="width: 150px;">
          <!-- <h2>{{ '15%' }} <span>{{ 'off' }}</span></h2> -->
          <h5>{{ appStore.loadedAds[0].title }}</h5>
          <p>{{ appStore.loadedAds[0].content }}!</p>
          <form class="modal-form">
            <div class="input-group modal-form-box">
              <input type="email" class="form-control" placeholder="Your email here">
              <WidgetsButton :classes="'input-group-text'" :id="'submit_newsletter_btn'" @click@="submit(emailVal.value)">
                {{ 'Submit' }}</WidgetsButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </WidgetsModalsCommon>
</template>
  
<script setup lang="ts">
import { EpcAds } from '~/lib/core/data/models/epc_ads_model';
import { useAppStore } from '~/lib/core/store/app_store';

const appStore = useAppStore()
let showModal = ref(false)
let selectedAd = ref<EpcAds>(EpcAds.empty())

onMounted(() => {
  // selectedAd.value = appStore.loadedAds.value[0]
  
  // if (localStorage.getItem('showModel') !== 'newsletter') {
    // console.log("cond: ", appStore.loadedAds[0].enabled);
    
    if(appStore.loadedAds[0] && appStore.loadedAds[0].enabled === 1 ) {
      showModal.value = true
    }
    // localStorage.setItem('showModel', 'newsletter')
  // }
})

function closeModal() {
  showModal.value = false
}
</script>
  
<style lang="scss" scoped></style>